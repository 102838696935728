import { render, staticRenderFns } from "./SidebarCiudadano.vue?vue&type=template&id=122c7830&scoped=true&"
import script from "./SidebarCiudadano.vue?vue&type=script&lang=ts&"
export * from "./SidebarCiudadano.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarCiudadano.vue?vue&type=style&index=0&id=122c7830&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122c7830",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicLogo: require('/opt/atlassian/pipelines/agent/build/components/public/Logo.vue').default,PublicAvatarMenu: require('/opt/atlassian/pipelines/agent/build/components/public/AvatarMenu.vue').default,IconsWalletIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/WalletIcon.vue').default})
