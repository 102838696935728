import { getLastSettings } from '@/services/Settings'

export const state = () => ({
  setting: {
    email_contact: '',
    redirectPath: '',
    isWithModules: true,
    isWithCertificates: true,
    hideInicioHeader: false,
    hideWhatIsHeader: false,
    hideCitizenHeader: false,
    hideRegisterLoginHeader: false,
    hideContentFooter: false,
    hideConnectWallet: false,
    hideFooter: false,
    withPayment: false,
    linkToChangePass: '',
    algoliaFacets: [
      'path',
      'title',
      'active',
      'parentPath',
      'config.showOnHomePage',
    ],
    walletForProcedures: false,
    operatorCanUnassign: false,
    simpleConclusion: false,
    nonWorkingDays: [],
    emailSuffix: '@os.city',
    seoSettings: {
      title: '',
      description: '',
      favicon: '',
    },
    platformLatLng: [16.49847115994006, -76.22896135],
    playlist: false,
    conjuction: false,
    activePrivateProcedures: false,
    activeShowAllFieldsAndFiles: false,
    permitEditForm: false,
    activeNewVersionTemplate: false,
    apiList: [],
    autocompleteFields: [],
    matchFieldUploadProcedures: {},
    truthProtest: '',
    shortPrivacyPolicy: '',
    fullPrivacyPolicy: '',
    isQuarkIdInstance: false,
    hideTimerPrevention: false,
    momentSetPaymentConfig: 'init',
  },
})

export const mutations = {
  SET_SETTING(state, setting) {
    state.setting = setting
  },
  UPDATE_ELEMENT(state, { element, data }) {
    state.setting = { ...state.setting, [element]: data }
  },
}

export const actions = {
  async get({ commit }) {
    // const self = this
    await getLastSettings(this.$fire).then((result) => {
      // const settings = rootState.setting.setting
      if (!result.empty) {
        const data = {
          ...result.docs[0].data(),
          id: result.docs[0].id,
        }
        commit('SET_SETTING', data)
      }
      // else {
      //   await self.$axios
      //     .$post('/saveCollectionData', {
      //       collection: 'settings',
      //       data: settings,
      //     })
      //     .catch((err) => {
      //       // eslint-disable-next-line no-console
      //       console.error(err)
      //     })
      // }
      /* @TODO: Bug documentos dobles en colecciones (firebase) */
      // else {
      //   const urlEndpoints = process.env.OSCITY_ENDPOINTS_URL
      //   await axios.post(
      //     `${urlEndpoints}/saveCollectionData`,
      //     {
      //       collection: 'settings',
      //       data: settings,
      //     }
      //   )
      // }
    })
  },
}

export const getters = {
  isWithEmailContact(state) {
    return (
      state.setting.email_contact &&
      state.setting.email_contact.length > 3 &&
      state.setting.email_contact.includes('@')
    )
  },
  isWithModules(state) {
    return state.setting.isWithModules
  },
  isWithCertificates(state) {
    return state.setting.isWithCertificates
  },
  hideCitizenHeader(state) {
    return state.setting.hideCitizenHeader
  },
  hideWhatIsHeader(state) {
    return state.setting.hideWhatIsHeader
  },
  hideInicioHeader(state) {
    return state.setting.hideInicioHeader
  },
  hideFooter(state) {
    return state.setting.hideFooter
  },
  hideRegisterLoginHeader(state) {
    return state.setting.hideRegisterLoginHeader
  },
  hideContentFooter(state) {
    return state.setting.hideContentFooter
  },
  hideConnectWallet(state) {
    return state.setting.hideConnectWallet
  },
  withPayment(state) {
    return state.setting.withPayment
  },
  getLinkToChangePass(state) {
    return state.setting.linkToChangePass
  },
  walletForProcedures(state) {
    return state.setting.walletForProcedures
  },
  nonWorkingDays(state) {
    return state.setting.nonWorkingDays
  },
  emailSuffix(state) {
    return state.setting.emailSuffix || '@os.city'
  },
  seoSettings(state) {
    return state.setting.seoSettings
  },
  platformLatLng(state) {
    return state.setting.platformLatLng
  },
  operatorCanUnassign(state) {
    return state.setting.operatorCanUnassign
  },
  simpleConclusion(state) {
    return state.setting.simpleConclusion
  },
  getConjuctionValue(state) {
    return state.setting.conjuction
  },
  activePrivateProcedures(state) {
    return state.setting.activePrivateProcedures
  },
  activeShowAllFieldsAndFiles(state) {
    return state.setting.activeShowAllFieldsAndFiles
  },
  permitEditForm(state) {
    return state.setting.permitEditForm
  },
  activeNewVersionTemplate(state) {
    return state.setting.activeNewVersionTemplate
  },
  apiList(state) {
    return state.setting.apiList
  },
  autocompleteFields(state) {
    return state.setting.autocompleteFields
  },
  truthProtest(state) {
    return state.setting.truthProtest
  },
  shortPrivacyPolicy(state) {
    return state.setting.shortPrivacyPolicy
  },
  fullPrivacyPolicy(state) {
    return state.setting.fullPrivacyPolicy
  },
  getQuarkIdInstanceValue(state) {
    return state.setting.isQuarkIdInstance
  },
  hideTimerPrevention(state) {
    return state.setting.hideTimerPrevention || false
  },
  momentSetPaymentConfig(state) {
    return state.setting.momentSetPaymentConfig || 'init'
  },
}
